import React from 'react';
import {NavLink} from "react-router-dom";
import {nav_items} from "../../../nav_items";

const Nav = () => {


    const NavItem = ({item}) => {
        return <li className='nav_item'>
            <NavLink to={item.path}>{item.title}</NavLink>
        </li>
    }

    return (
        <nav className='nav'>
            <ul className='nav_wrapper'>
                {nav_items.map(item => {
                    return <NavItem
                        key={item.id}
                        item={item}
                    />
                })}
                <div className={'aidrop'}>
                   <span>Airdrop</span>
                </div>
            </ul>
        </nav>
    );
};

export default Nav;